import React from 'react'

const Mail = () => {
  return (
    <>
        <div className='emptyDiv'>

        </div>
        <p style={{fontSize : '2rem' , textAlign : 'center'}}>Mails </p>
        <div className='mailHead'>
            <span>2</span> <p>You have received 2  new Mail</p>
        </div>
        <div className='mailTwo-sec'>
            <div className='empty'>

            </div>
            <div className='para'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse vestibulum velit non neque consequat, eu consequat mi lacinia. Donec vitae dui quis nibh auctor consequat. Aliquam erat volutpat. Nam sagittis nisi a sapien fringilla rhoncus. Nullam feugiat nisl ac magna fermentum hendrerit. Proin ac velit id eros varius vehicula. Sed dapibus ipsum in nibh aliquet interdum. Maecenas tristique nulla at ultrices faucibus. Aliquam erat volutpat. Nam malesuada euismod sapien, eu sollicitudin dolor placerat vel. In ut massa dolor.</div>
        </div>
        <div className='mailTwo-sec'>
            <div className='empty'>

            </div>
            <div className='para'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse vestibulum velit non neque consequat, eu consequat mi lacinia. Donec vitae dui quis nibh auctor consequat. Aliquam erat volutpat. Nam sagittis nisi a sapien fringilla rhoncus. Nullam feugiat nisl ac magna fermentum hendrerit. Proin ac velit id eros varius vehicula. Sed dapibus ipsum in nibh aliquet interdum. Maecenas tristique nulla at ultrices faucibus. Aliquam erat volutpat. Nam malesuada euismod sapien, eu sollicitudin dolor placerat vel. In ut massa dolor.</div>
        </div>
    </>
  )
}

export default Mail